import React from 'react';
import SEO from '../components/SEO';
import { ImageGrid, ImageCaption } from '../styles/ImageGrid';
import { TripHeader } from '../components/TripHeader';
import Usoutsideoxford2withwaitress from '../assets/images/usoutsideoxford2withwaitress.jpg';
import Usatcornerbooth from '../assets/images/usatcornerbooth.jpg';
import Baileywithmike from '../assets/images/baileywithmike.jpg';
import Hornsbybrett from '../assets/images/hornsby-brett.jpg';
import Uswithoxfordwaitresses from '../assets/images/uswithoxfordwaitresses.jpg';
import Usoutsideoxford2 from '../assets/images/usoutsideoxford2.jpg';
import Oxfordvalentinedaysign from '../assets/images/oxfordvalentinedaysign.jpg';
import Oxfordwaitresses from '../assets/images/oxfordwaitresses.jpg';
import Oxford2hh from '../assets/images/oxford2hh.jpg';
import Jacksonvillebigsign from '../assets/images/jacksonvillebigsign.jpg';
import Uswithcop from '../assets/images/uswithcop.jpg';

export default function Page() {
  return (
    <>
      <SEO title="Oxford: 2-10-01" />
      <div>
        <TripHeader>
          <h2>Oxford, Oxford 2, Jacksonville</h2>
          <p>2-10-01</p>
          <img
            src={Usoutsideoxford2withwaitress}
            alt="Brett, Hornsby, Derek, & Pooh with Sandra"
          />
          <ImageCaption>Brett, Hornsby, Derek, & Pooh with Sandra</ImageCaption>
        </TripHeader>
        <ImageGrid>
          <div>
            <img src={Usatcornerbooth} alt="Us at the Oxford 2 Corner Booth" />
            <ImageCaption>Us at the Oxford 2 Corner Booth</ImageCaption>
          </div>
          <div>
            <img src={Baileywithmike} alt="Bailey with Mike" />
            <ImageCaption>Bailey with Mike</ImageCaption>
          </div>
          <div>
            <img src={Hornsbybrett} alt="Hornsby & Brett in Oxford 2" />
            <ImageCaption>Hornsby & Brett in Oxford 2</ImageCaption>
          </div>
        </ImageGrid>
        <p>
          This was one of the greatest trips ever. We went to three in all. Our
          usual one, the other one in Oxford, and the one in Jacksonville. The
          other Oxford Huddle House is probably the second best we have ever
          been to. Moultrie and Honey being first. This was a close second.
          Sandra was very nice and the cook Mike was also great. We meet several
          other regulars there including a cop and another group. They were all
          very nice. We also went out to Jacksonville and took pictures of the
          one there, although we didn't go in. Then we went back to our old
          Huddle House and finally saw MIke again! Mike was the first regular we
          ever meet, and that was back before we started taking the camera so we
          never got a picture with Mike...until now. Bailey got his picture made
          with Mike and we all very excited to see him again. We also took along
          Derek, Jonathan, and Nathan for the first time. Another great time at
          the Huddle House!
        </p>
        <ImageGrid>
          <div>
            <img src={Uswithcop} alt="Us with Cop" />
            <ImageCaption>Us with Cop</ImageCaption>
          </div>
          <div>
            <img
              src={Uswithoxfordwaitresses}
              alt="Us with Oxford Waitressess"
            />
            <ImageCaption>Us with Oxford Waitressess</ImageCaption>
          </div>
          <div>
            <img src={Usoutsideoxford2} alt="Outside Oxford 2" />
            <ImageCaption>Outside Oxford 2</ImageCaption>
          </div>
          <div>
            <img
              src={Oxfordvalentinedaysign}
              alt="Oxford Valentine's Day Sign"
            />
            <ImageCaption>Oxford Valentine's Day Sign</ImageCaption>
          </div>
          <div>
            <img src={Oxfordwaitresses} alt="Oxford Waitresses" />
            <ImageCaption>Oxford Waitresses</ImageCaption>
          </div>
          <div>
            <img src={Oxford2hh} alt="At Oxford 2" />
            <ImageCaption>At Oxford 2</ImageCaption>
          </div>
          <div>
            <img src={Jacksonvillebigsign} alt="Jacksonville Sign" />
            <ImageCaption>Jacksonville Sign</ImageCaption>
          </div>
        </ImageGrid>
      </div>
    </>
  );
}
